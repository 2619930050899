<template>
  <v-app>
      <NavBar />
    <v-main>
      <router-view />
    </v-main>
    <Footer />
  </v-app>
</template>

<script>
import Footer from "@/components/Footer.vue";
import Walmart from "@/assets/images/Intro.png";

export default {
  components: {
    Footer
  },
  data: () => ({
    Walmart,
  }),
};
</script>