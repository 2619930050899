<template>
  <div>
    <div>

      <v-row justify="center">
        <v-dialog v-model="dialog" width="430px">
          <v-card>
            <v-card-text>
              <div class="text-center">
                <br>
                <br>
                <label style="font-family: Helvetica; font-style: normal; font-weight: 400; font-size: 18px; line-height: 140%; letter-spacing: 0.02em;
                  color: black; flex: none; order: 1; flex-grow: 0;">Estas son las coberturas y
                  protecciones del</label>
                <br>
                <br>
                <label style="font-family: Helvetica; font-style: normal; font-weight: 400; font-size: 18px; line-height: 140%; letter-spacing: 0.02em;
                  color: black; flex: none; order: 1; flex-grow: 0;">Plan <label style="font-family: Helvetica; font-style: normal; font-weight: 600; 
                  font-size: 20px; line-height: 140%; letter-spacing: 0.02em;
                  color: #2D387C; flex: none; order: 1; flex-grow: 0; text-transform: uppercase;">{{ info[0][indexInfo]
                    }}</label> de
                  Mascotas</label>
                <br>
                <br>
                <label class="text-center encabezado">Libre elección y sin deducible</label>
                <br>
                <br>
                <label class="texto">Puedes llevar a tu mascota a la
                  clínica veterinaria <label class="textoAzul">que tú quieras</label>, sin restricciones. Además, todas
                  nuestras coberturas son <label class="textoAzul">sin
                    deducible</label>.</label>
                <br>
                <br>
                <label class="text-center encabezado">Urgencias Médicas</label>
                <br>
                <br>
                <label class="texto">Te devolveremos hasta el <label class="textoAzul">{{ info[1][indexInfo] }}</label>
                  de
                  tus
                  gastos frente a urgencias veterinarias sin deducibles en cualquier clínica. <label
                    class="textoAzul">Tope
                    UF {{ info[2][indexInfo] }}</label>.</label>
                <br>
                <br>
                <label class="texto">Puedes usarlo a los <label class="textoAzul">15 días</label> de
                  contratación y hasta <label class="textoAzul">{{ info[3][indexInfo] }} veces al año</label>.</label>
                <br>
                <br>
                <label class="text-center encabezado">Consultas Médicas</label>
                <br>
                <br>
                <label class="texto">Reembolsamos las consultas médicas y
                  controles de tu mascota con el Veterinario que tú quieras. <label class="textoAzul">Tope UF
                    {{ info[4][indexInfo] }}</label>.</label>
                <br>
                <br>
                <label class="texto">Puedes usarlo a los <label class="textoAzul">15 días</label> de
                  contratación y <label v-if="info[5][indexInfo] == 1" class="textoAzul">{{ info[5][indexInfo] }} vez al
                    año</label> <label v-if="info[5][indexInfo] != 1" class="textoAzul">{{ info[5][indexInfo] }} veces
                    al
                    año</label>.</label>
                <br>
                <br>

                <div v-if="indexInfo != 3">

                  <label class="text-center encabezado">Medicamentos</label>
                  <br>
                  <br>
                  <label class="texto">¿Le recetaron remedios a tu mascota?
                    Te damos hasta <label class="textoAzul">$10.000 al mes</label> en reembolsos para medicamentos
                    veterinarios con receta.</label>
                  <br>
                  <br>
                  <label class="texto">Puedes usarlo a los <label class="textoAzul">30 días</label> de
                    contratación y <label class="textoAzul">1 vez al mes</label>.</label>
                  <br>
                  <br>
                  <label class="text-center encabezado">Vacuna Antirrábica</label>
                  <br>
                  <br>
                  <label class="texto">Te reembolsamos <label class="textoAzul">UF 0,5</label> en el pago de
                    la vacuna de tu mascota en cualquier clínica.</label>
                  <br>
                  <br>
                  <label class="texto">Puedes usarlo a los <label class="textoAzul">30 días</label> de
                    contratación y <label class="textoAzul">1 vez al año</label>.</label>
                  <br>
                  <br>

                </div>

                <div v-if="indexInfo == 3">

                  <label class="text-center encabezado">Vacunas Obligatorias</label>
                  <br>
                  <br>
                  <label class="texto">Te reembolsamos <label class="textoAzul">UF 0,5</label> en el pago de
                    la vacuna de tu mascota en cualquier clínica.</label>
                  <br>
                  <br>
                  <label class="texto">Puedes usarlo a los <label class="textoAzul">15 días</label> de
                    contratación y <label class="textoAzul">2 veces al año</label>.</label>
                  <br>
                  <br>

                </div>

                <label class="text-center encabezado">Responsabilidad Civil</label>
                <br>
                <br>
                <label class="texto">Tu mascota se portó mal y mordió o
                  hizo algún daño a otra persona, te ayudamos en caso de demanda con <label class="textoAzul">UF {{
                    info[8][indexInfo] }}
                  </label> si tienes que pagar por los
                  daños.</label>
                <br>
                <br>
                <label class="texto">Puedes usarlo a los <label class="textoAzul">0 días</label> de
                  contratación y <label class="textoAzul">1 vez al año</label>.</label>
                <br>
                <br>
                <label class="text-center encabezado">Telemedicina Veterinaria</label>
                <br>
                <br>
                <label class="texto">¿Tienes dudas de salud de tu mascota?
                  Deja que nuestros expertos te ayuden llamando sin costo a la hora que necesites.</label>
                <br>
                <br>
                <label class="texto">Puedes usarlo a los <label class="textoAzul">{{ info[9][indexInfo] }} días</label>
                  de
                  contratación y hasta <label class="textoAzul">{{ info[6][indexInfo] }} veces al año</label>.</label>
                <br>
                <br>

                <div v-if="indexInfo != 3">

                  <label class="text-center encabezado">Asesoría Legal Teléfonica</label>
                  <br>
                  <br>
                  <label class="texto">¿Necesitas ayuda con temas legales
                    por algo de tu mascota? Deja que nuestros expertos te ayuden llamando sin costo a la hora que
                    necesites.</label>
                  <br>
                  <br>
                  <label class="texto">Puedes usarlo a los <label class="textoAzul">15 días</label> de
                    contratación y hasta <label class="textoAzul">{{ info[7][indexInfo] }} veces al año</label>.
                  </label>

                </div>

                <div v-if="indexInfo == 3">

                  <label class="text-center encabezado">Cremación</label>
                  <br>
                  <br>
                  <label class="texto">Si a consecuencia de un accidente o por vejez tu mascota fallece, te apoyamos con
                    <label class="textoAzul">UF 3.</label></label>
                  <br>
                  <br>
                  <label class="texto">Puedes usarlo a los <label class="textoAzul"> 60 días</label>
                    de
                    contratación.</label>

                </div>

                <br>

              </div>
            </v-card-text>
            <v-card-actions>
              <v-btn style="box-sizing: border-box; padding: 12px 24px; gap: 10px; background: #FFB600; border: 2px solid #FFB600;
                    border-radius: 8px; margin-top: -6%; font-family: Helvetica; font-style: normal; font-weight: 700; font-size: 14px; line-height: 17px;
                    letter-spacing: 0.02em; color: white; flex: none; order: 0; flex-grow: 0; margin:auto;"
                elevation="0" text @click="dialog = false">
                Aceptar
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-row>

    </div>

    <div v-if="planData.name == 'Plan Senior' && tipoPlan == true">

      <div class="plan">

        <v-col class="text-center">
          <br>
          <br>
          <br>

          <p class="text-center" style="font-family: Helvetica; font-style: normal; font-weight: 700; font-size: 35px; line-height: 28px;
            color: #FFFFFF; flex: none; order: 0; flex-grow: 0;">{{ planData.name }}</p>

          <label class="text-center" style="color: white; font-size:32px">{{ formatPrice(planData.priceCLP) }}</label>

          <br>

          <label class="text-center" style="font-family: Helvetica; font-style: normal; font-weight: 400; font-size: 12px; line-height: 15px;
            color: rgba(255, 255, 255, 0.8); flex: none; order: 1; flex-grow: 0;">UF {{
              (planData.values[0].price).toFixed(2) }}
            mensual</label>

          <br>
          <br>

          <hr
            style="margin: auto; border: 0.946207px solid #FFFFFF; flex: none; order: 1; flex-grow: 0; width: 300px; height: 0px;">

          <br>

          <label style="font-family: Helvetica; font-style: normal; font-weight: 400; font-size: 12px; line-height: 140%; text-align: center;
            letter-spacing: 0.02em; color: #FFFFFF; flex: none; order: 2; flex-grow: 0;">Protege a tu mascota con un
            50% del arancel en <br> urgencias veterinarias.</label>

          <p></p>

          <label style="font-family: Helvetica; font-style: normal; font-weight: 600; font-size: 15px; line-height: 128.02%; text-align: center; letter-spacing: 0.02em;
          color: #FFFFFF; flex: none; order: 0; flex-grow: 0;;">Incluye</label>

          <p></p>
          <div class="container-details">
            <li style="font-family: Helvetica; font-style: normal; font-weight: 400; font-size: 14px; line-height: 140%; letter-spacing: 0.02em;
              color: #FFFFFF; flex: none; order: 1; flex-grow: 0; text-align: left; "> {{
                planData.coverage[1].description }}
              <p
                style="text-align: left; font-family: Helvetica; font-style: normal; 
                  font-weight: 400; font-size: 14px; line-height: 140%; letter-spacing: 0.02em; color: #FFFFFF; flex: none; order: 1; flex-grow: 0; text-align: left; ">
                {{ planData.coverage[1].description2 }}
              </p>
            </li>

            <li style="font-family: Helvetica; font-style: normal; font-weight: 400; font-size: 14px; line-height: 140%; letter-spacing: 0.02em;
              color: #FFFFFF; flex: none; order: 1; flex-grow: 0; text-align: left; "> {{
                planData.coverage[2].description }}<p></p>
            </li>

            <li style="font-family: Helvetica; font-style: normal; font-weight: 400; font-size: 14px; line-height: 140%; letter-spacing: 0.02em;
              color: #FFFFFF; flex: none; order: 1; flex-grow: 0; text-align: left; ">
              {{ planData.coverage[3].description }}<p></p>
            </li>

            <li style="font-family: Helvetica; font-style: normal; font-weight: 400; font-size: 14px; line-height: 140%; letter-spacing: 0.02em;
            color: #FFFFFF; flex: none; order: 1; flex-grow: 0; text-align: left; ">
              {{ planData.coverage[5].description }}<p></p>
            </li>
          </div>
          <br>

          <hr
            style="margin: auto; border: 0.946207px solid #FFFFFF; flex: none; order: 1; flex-grow: 0; width: 300px; height: 0px;">

          <br>

          <div class="plan-info">
            <u style="box-sizing: border-box; padding: 12px 24px; gap: 10px; 
              border-radius: 8px; margin-top: -6%; font-family: Helvetica; font-style: normal; font-weight: 700; font-size: 14px; line-height: 17px;
            letter-spacing: 0.02em; color: white; flex: none; order: 0; flex-grow: 0; width: 300px;" elevation="0"
              @click="Info(index)">
              VER DETALLE
            </u>
          </div>

        </v-col>

        <br>

        <div class="plan-info">
          <v-btn style="background-color: #2D387C; color: white; border-radius: 12px; width: 350px; margin-top: 4%; "
            elevation="0" @click="validate()"> Seleccionar
          </v-btn>
        </div>
        <br>
      </div>

    </div>


    <div v-if="planData.name == 'Plan Básico' && tipoPlan == false">

      <div class="plan">

        <v-col class="text-center">
          <br>
          <br>
          <br>
          <br>

          <p class="text-center" style="font-family: Helvetica; font-style: normal; font-weight: 700; font-size: 35px; line-height: 28px;
          color: #FFFFFF; flex: none; order: 0; flex-grow: 0;">{{ planData.name }}</p>

          <label class="text-center" style="color: white; font-size:32px">{{ formatPrice(planData.priceCLP) }}</label>

          <br>

          <label class="text-center" style="font-family: Helvetica; font-style: normal; font-weight: 400; font-size: 12px; line-height: 15px;
          color: rgba(255, 255, 255, 0.8); flex: none; order: 1; flex-grow: 0;">UF {{
            (planData.values[0].price).toFixed(2) }}
            mensual</label>

          <br>
          <br>

          <hr
            style="margin: auto; border: 0.946207px solid #FFFFFF; flex: none; order: 1; flex-grow: 0; width: 300px; height: 0px;">

          <br>
          <div class="container-details">

            <li style="font-family: Helvetica; font-style: normal; font-weight: 400; font-size: 14px; line-height: 140%; letter-spacing: 0.02em;
          color: #FFFFFF; flex: none; order: 1; flex-grow: 0; text-align: left; "> Cubre {{
            planData.coverage[0].amount }}% en Urgencia con tope <p
                style="text-align: left; font-family: Helvetica; font-style: normal; 
          font-weight: 400; font-size: 14px; line-height: 140%; letter-spacing: 0.02em; color: #FFFFFF; flex: none; order: 1; flex-grow: 0; text-align: left; margin-left: -16%;">
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;de UF {{
                  planData.coverage[4].amount }}. </p>
            </li>

            <li style="font-family: Helvetica; font-style: normal; font-weight: 400; font-size: 14px; line-height: 140%; letter-spacing: 0.02em;
            color: #FFFFFF; flex: none; order: 1; flex-grow: 0; text-align: left; ">UF
              {{ planData.coverage[1].amount }}
              en Responsabilidad Civil.<p></p>
            </li>

            <li style="font-family: Helvetica; font-style: normal; font-weight: 400; font-size: 14px; line-height: 140%; letter-spacing: 0.02em;
              color: #FFFFFF; flex: none; order: 1; flex-grow: 0; text-align: left; ">UF {{
                planData.coverage[2].amount }}
              en Consultas Médicas Libre.<p></p>
            </li>

            <li style="font-family: Helvetica; font-style: normal; font-weight: 400; font-size: 14px; line-height: 140%; letter-spacing: 0.02em;
            color: #FFFFFF; flex: none; order: 1; flex-grow: 0; text-align: left; "> $10.000 x mes en
              reembolso de <p style="text-align: left; font-family: Helvetica; font-style: normal; 
              font-weight: 400; font-size: 14px; line-height: 140%; letter-spacing: 0.02em; color: #FFFFFF; flex: none; order: 1; flex-grow: 0; text-align: left; 
              ">
                medicamentos
                (Tope 50% boleta)</p>
            </li>

            <li style="font-family: Helvetica; font-style: normal; font-weight: 400; font-size: 14px; line-height: 140%; letter-spacing: 0.02em;
            color: #FFFFFF; flex: none; order: 1; flex-grow: 0; text-align: left; "> Asistencia Legal
              Teléfonica.<p></p>
            </li>

          </div>
          <br>

          <hr
            style="margin: auto; border: 0.946207px solid #FFFFFF; flex: none; order: 1; flex-grow: 0; width: 300px; height: 0px;">

          <br>

          <div class="plan-info">
            <u style="box-sizing: border-box; padding: 12px 24px; gap: 10px; 
              border-radius: 8px; margin-top: -6%; font-family: Helvetica; font-style: normal; font-weight: 700; font-size: 14px; line-height: 17px;
              letter-spacing: 0.02em; color: white; flex: none; order: 0; flex-grow: 0; width: 300px;" elevation="0"
              @click="Info(index)"> VER DETALLE
            </u>
          </div>

        </v-col>

        <br>
        <br>
        <br>

        <div class="plan-info">
          <v-btn style="background-color: #2D387C; color: white; border-radius: 12px; width: 350px; margin-top: 10%;"
            elevation="0" @click="validate()"> Seleccionar
          </v-btn>
        </div>

      </div>
      <br>
      <br>


    </div>

    <div v-if="planData.name != 'Plan Básico' && tipoPlan == false" class="plan2">

      <v-col class="text-center">

        <br>
        <br>
        <br>
        <br>

        <label class="text-center" style="color: #2D387C; font-size:32px">{{ planData.name }}</label>

        <br>
        <br>

        <label class="text-center" style="color: #2D387C; font-size:32px">{{ formatPrice(planData.priceCLP) }}</label>

        <br>

        <label class="text-center" style="font-family: Helvetica; font-style: normal; font-weight: 400; font-size: 12px; line-height: 15px;
      color: #2D387C; flex: none; order: 1; flex-grow: 0;">UF {{ (planData.values[0].price).toFixed(2) }}
          mensual</label>

        <br>

        <hr
          style="margin: auto; border: 1px solid rgba(0, 0, 0, 0.3); flex: none; order: 1; flex-grow: 0; width: 250px; height: 0px;">

        <br>
        <br>

        <div class="container-details">

          <li style="font-family: Helvetica; font-style: normal; font-weight: 400; font-size: 14px; line-height: 140%; letter-spacing: 0.02em;
        color: rgba(0, 0, 0, 0.5); flex: none; order: 1; flex-grow: 0; text-align: left; ;"> Cubre {{
          planData.coverage[0].amount
        }}% en
            Urgencia con tope
            <p style="text-align: left; font-family: Helvetica; font-style: normal; font-weight: 400; font-size: 14px; line-height: 140%; letter-spacing: 0.02em;
        color: rgba(0, 0, 0, 0.5); flex: none; order: 1; flex-grow: 0; text-align: left; ">
              de UF
              {{ planData.coverage[4].amount }}.</p>
          </li>

          <li style="font-family: Helvetica; font-style: normal; font-weight: 400; font-size: 14px; line-height: 140%; letter-spacing: 0.02em;
      color: rgba(0, 0, 0, 0.5); flex: none; order: 1; flex-grow: 0; text-align: left; ;">UF {{
        planData.coverage[1].amount
            }} en
            Responsabilidad Civil.<p></p>
          </li>

          <li style="font-family: Helvetica; font-style: normal; font-weight: 400; font-size: 14px; line-height: 140%; letter-spacing: 0.02em;
        color: rgba(0, 0, 0, 0.5); flex: none; order: 1; flex-grow: 0; text-align: left; ;">UF {{
          planData.coverage[2].amount
        }} en
            Consultas Médicas Libre.<p></p>
          </li>

          <li style="font-family: Helvetica; font-style: normal; font-weight: 400; font-size: 14px; line-height: 140%; letter-spacing: 0.02em;
        color: rgba(0, 0, 0, 0.5); flex: none; order: 1; flex-grow: 0; text-align: left; ;"> $10.000 x
            mes
            en reembolso de
            <p style="text-align: left; font-family: Helvetica; font-style: normal; font-weight: 400; font-size: 14px; line-height: 140%; letter-spacing: 0.02em;
        color: rgba(0, 0, 0, 0.5); flex: none; order: 1; flex-grow: 0; text-align: left; ">
              medicamentos (Tope
              50% boleta)</p>
          </li>

          <li style="font-family: Helvetica; font-style: normal; font-weight: 400; font-size: 14px; line-height: 140%; letter-spacing: 0.02em;
        color: rgba(0, 0, 0, 0.5); flex: none; order: 1; flex-grow: 0; text-align: left; ;"> Asistencia
            Legal Teléfonica.<p></p>
          </li>

        </div>

        <hr
          style="margin: auto; border: 1px solid rgba(0, 0, 0, 0.3); flex: none; order: 1; flex-grow: 0; width: 250px; height: 0px;">

        <div class="plan-info">
          <u style="padding: 12px 24px; gap: 10px; color:#2D387C; border-radius: 8px; margin-top: -5%;
        flex: none; order: 7; flex-grow: 0; width: 250px;" elevation="0" @click="Info(index)"> VER DETALLE </u>
        </div>

      </v-col>

      <div class="plan-info">
        <v-btn style="background-color: #2D387C; color: white; border-radius: 12px; margin: 10%; width: 300px;"
          elevation="0" @click="validate()"> Seleccionar
        </v-btn>

      </div>

    </div>

  </div>
</template>

<script>
import LibreEleccion from "../assets/images/libreeleccion.png"
import ShieldService from "../services/shield.service";

import CryptoJS from 'crypto-js'

export default {
  name: "Plan",
  props: ["planData", "planSenior", "index", "tipoPlan", "userData", "planDataAux", "servicesData"],
  data: () => ({
    secretKey: process.env.VUE_APP_CRYPT,
    formatter: new Intl.NumberFormat("es-CL", {
      style: "currency",
      currency: "CLP",
    }),
    LibreEleccion,
    info: [['Plan Lite', 'Plan Básico', 'Plan Plus', 'Plan Senior'], ['40%', '60%', '80%', '50%'], ['50', '100', '250', '20'], ['2', '2', '3', '3'], ['1', '2', '3', '3'], ['1', '1', '2', '4'], ['3', '4', '6', '6'], ['2', '2', '4', '4'], ['3', '10', '15', '8'], ['15', '15', '15', '30']],
    indexInfo: '0',
    dialog: false,
    activeCampaingUse: process.env.VUE_APP_ACTIVE_CAMPAIGN ? process.env.VUE_APP_ACTIVE_CAMPAIGN : 'FALSE',
  }),
  methods: {
    Info(index) {
      if (this.planData.name == 'Plan Senior') {
        this.indexInfo = 3
      } else {
        this.indexInfo = index
      }
      this.dialog = true
    },
    formatPrice(number) {
      return this.formatter.format(number);
    },
    ufchange(uf) {
      let nn = uf.toString();
      let new_uf = nn.replace('.', ',')
      return new_uf;
    },
    async validate() {

      this.planDataAux.plan = this.planData;

      const planDataLocal = JSON.stringify(this.planDataAux);
      const encryptedPlanDataLocal = CryptoJS.AES.encrypt(planDataLocal, this.secretKey).toString();
      localStorage.setItem('planData', encryptedPlanDataLocal);

      try {

        await ShieldService.newCreateStep(null, null, this.planDataAux, this.servicesData, 'Planes');

      } catch (error) {
        console.log("Cart Fail");
      }

      if (this.userData.email && this.activeCampaingUse == 'TRUE') {
        const activeCampaingRes = await ShieldService.getContactActiveCampain(this.userData.email);

        this.servicesData.contactID = activeCampaingRes.data.data.contacts[0].id;

        await ShieldService.updateContactActiveCampain(null, this.planDataAux, null, this.servicesData, 1);

        const servicesDataLocal = JSON.stringify(this.servicesData);
        const encryptedServicesDataLocal = CryptoJS.AES.encrypt(servicesDataLocal, this.secretKey).toString();
        localStorage.setItem('servicesData', encryptedServicesDataLocal);

      }

      this.$router.push({ path: '/mt/datos' })
    },
  },
  mounted() {
  },
};
</script>

<style lang="scss" scoped>
.texto {
  font-family: Helvetica;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 140%;
  letter-spacing: 0.02em;
  color: rgba(0, 0, 0, 0.5);
  flex: none;
  order: 1;
  flex-grow: 0;
}

.textoAzul {
  font-family: Helvetica;
  font-style: normal;
  font-weight: 400px;
  font-size: 14px;
  line-height: 140%;
  letter-spacing: 0.02em;
  color: #2D387C;
  flex: none;
  order: 1;
  flex-grow: 0;
}

.encabezado {
  color: #2D387C;
  font-size: 18px;
  font-weight: 600;
}

.container-details {
  width: 100%;
  margin: auto;
  padding-left: 13%;
}

.plan-info {
  margin-top: 2rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.plan {
  background-color: #ffffff;
  background-image: url("~@/assets/images/fondoPlanRecomendaado2.png");
  background-position: center;
  background-size: cover;
  width: 350px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 0.5rem;
  align-items: center;
  padding: 0.75rem;
  height: 680px;
}

.plan2 {
  background-color: #ffffff;
  background-image: url("~@/assets/images/planNormal.png");
  background-position: center;
  background-size: cover;
  width: 350px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0.75rem;
  margin-top: 15%;
  height: 590px;
}
</style>