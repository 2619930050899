import dogBreeds from "../utils/dog-breeds";
import catBreeds from "../utils/cat-breeds";

export default class BreedsService {
  static async getBreeds(species) {
    try {
      let breeds = [];
      if (species === "dog") {
        breeds = dogBreeds;
      } else if (species === "cat") {
        breeds = catBreeds;
      }
      return breeds;
    } catch (error) {
      return {
        status: "error",
        message: "Ocurrio un error al obtener las razas",
      };
    }
  }
}
