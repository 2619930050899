export default [
    {
      "name": "Mestizo",
      "id": ""
    },
    {
      "name": "Abisinio",
      "id": "C1"
    },
    {
      "name": "Africano doméstico",
      "id": "C2"
    },
    {
      "name": "American Curl",
      "id": "C3"
    },
    {
      "name": "American shorthair",
      "id": "C4"
    },
    {
      "name": "American wirehair",
      "id": "C5"
    },
    {
      "name": "Angora turco",
      "id": "C6"
    },
    {
      "name": "Aphrodite-s Giants",
      "id": "C7"
    },
    {
      "name": "Australian Mist",
      "id": "C8"
    },
    {
      "name": "Azul ruso",
      "id": "C9"
    },
    {
      "name": "Bengala",
      "id": "C10"
    },
    {
      "name": "Bobtail japonés",
      "id": "C11"
    },
    {
      "name": "Bombay",
      "id": "C12"
    },
    {
      "name": "Bosque de Noruega",
      "id": "C13"
    },
    {
      "name": "Brazilian Shorthair",
      "id": "C14"
    },
    {
      "name": "British Shorthair",
      "id": "C15"
    },
    {
      "name": "Burmés",
      "id": "C16"
    },
    {
      "name": "Burmilla",
      "id": "C17"
    },
    {
      "name": "California Spangled",
      "id": "C18"
    },
    {
      "name": "Ceylon",
      "id": "C19"
    },
    {
      "name": "Chartreux",
      "id": "C20"
    },
    {
      "name": "Cornish rex",
      "id": "C21"
    },
    {
      "name": "Cymric",
      "id": "C22"
    },
    {
      "name": "Deutsch Langhaar",
      "id": "C23"
    },
    {
      "name": "Devon rex",
      "id": "C24"
    },
    {
      "name": "Don Sphynx",
      "id": "C25"
    },
    {
      "name": "Dorado africano",
      "id": "C26"
    },
    {
      "name": "Europeo común",
      "id": "C27"
    },
    {
      "name": "Gato exótico",
      "id": "C28"
    },
    {
      "name": "German Rex",
      "id": "C29"
    },
    {
      "name": "Habana brown",
      "id": "C30"
    },
    {
      "name": "Himalayo",
      "id": "C31"
    },
    {
      "name": "Khao Manee",
      "id": "C32"
    },
    {
      "name": "Korat",
      "id": "C33"
    },
    {
      "name": "Maine Coon",
      "id": "C34"
    },
    {
      "name": "Manx",
      "id": "C35"
    },
    {
      "name": "Mau egipcio",
      "id": "C36"
    },
    {
      "name": "Munchkin",
      "id": "C37"
    },
    {
      "name": "Ocicat",
      "id": "C38"
    },
    {
      "name": "Ojos azules",
      "id": "C39"
    },
    {
      "name": "Oriental",
      "id": "C40"
    },
    {
      "name": "Oriental de pelo largo",
      "id": "C41"
    },
    {
      "name": "Persa",
      "id": "C42"
    },
    {
      "name": "Peterbald",
      "id": "C43"
    },
    {
      "name": "Pixi Bob",
      "id": "C44"
    },
    {
      "name": "Ragdoll",
      "id": "C45"
    },
    {
      "name": "Sagrado de Birmania",
      "id": "C46"
    },
    {
      "name": "Scottish Fold",
      "id": "C47"
    },
    {
      "name": "Selkirk rex",
      "id": "C48"
    },
    {
      "name": "Serengeti",
      "id": "C49"
    },
    {
      "name": "Seychellois",
      "id": "C50"
    },
    {
      "name": "Siamés",
      "id": "C51"
    },
    {
      "name": "Siamés Moderno",
      "id": "C52"
    },
    {
      "name": "Siamés Tradicional",
      "id": "C53"
    },
    {
      "name": "Siberiano",
      "id": "C54"
    },
    {
      "name": "Snowshoe",
      "id": "C55"
    },
    {
      "name": "Sphynx",
      "id": "C56"
    },
    {
      "name": "Tonkinés",
      "id": "C57"
    },
    {
      "name": "Van Turco",
      "id": "C58"
    }
];
