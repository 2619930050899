export default [
  {
    id: 16,
    label: "10 años",
    unit: "Y",
    quantity: 10,
    code: "10Y",
  },
  {
    id: 17,
    label: "11 años",
    unit: "Y",
    quantity: 11,
    code: "10Y",
  },
  {
    id: 18,
    label: "12 años",
    unit: "Y",
    quantity: 12,
  },
  {
    id: 19,
    label: "13 años",
    unit: "Y",
    quantity: 13,
  },
  {
    id: 20,
    label: "14 años",
    unit: "Y",
    quantity: 14,
  },
  {
    id: 21,
    label: "15 años",
    unit: "Y",
    quantity: 15,
  },
  {
    id: 22,
    label: "16 años",
    unit: "Y",
    quantity: 16,
  },
  {
    id: 23,
    label: "17 años",
    unit: "Y",
    quantity: 17,
  },
  {
    id: 24,
    label: "18 años",
    unit: "Y",
    quantity: 18,
  },
  {
    id: 25,
    label: "19 años",
    unit: "Y",
    quantity: 19,
  },
  {
    id: 26,
    label: "20 años",
    unit: "Y",
    quantity: 20,
  },
];