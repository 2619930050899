export default [
  {
    id: 1,
    label: "6 meses",
    unit: "M",
    quantity: 6,
    code: "6M",
  },
  {
    id: 2,
    label: "7 meses",
    unit: "M",
    quantity: 7,
    code: "7M",
    
  },
  {
    id: 3,
    label: "8 meses",
    unit: "M",
    quantity: 8,
    code: "8M",
  },
  {
    id: 4,
    label: "8 meses",
    unit: "M",
    quantity: 8,
    code: "8M",
  },
  {
    id: 5,
    label: "9 meses",
    unit: "M",
    quantity: 9,
    code: "9M",
  },
  {
    id: 6,
    label: "10 meses",
    unit: "M",
    quantity: 10,
    code: "10M",
  },
  {
    id: 7,
    label: "11 meses",
    unit: "M",
    quantity: 11,
    code: "11M",
  },
  {
    id: 8,
    label: "1 año",
    unit: "Y",
    quantity: 1,
    code: "1Y",
  },
  {
    id: 9,
    label: "2 años",
    unit: "Y",
    quantity: 2,
    code: "2Y",
  },
  {
    id: 10,
    label: "3 años",
    unit: "Y",
    quantity: 3,
    code: "3Y",
  },
  {
    id: 11,
    label: "4 años",
    unit: "Y",
    quantity: 4,
    code: "4Y",
  },
  {
    id: 11,
    label: "4 años",
    unit: "Y",
    quantity: 4,
    code: "4Y",
    
  },
  {
    id: 12,
    label: "5 años",
    unit: "Y",
    quantity: 5,
    code: "5Y",
  },
  {
    id: 13,
    label: "6 años",
    unit: "Y",
    quantity: 6,
    code: "6Y",
  },
  {
    id: 14,
    label: "7 años",
    unit: "Y",
    quantity: 7,
    code: "7Y",
  },
  {
    id: 15,
    label: "8 años",
    unit: "Y",
    quantity: 8,
    code: "8Y",
  },
  {
    id: 15,
    label: "9 años",
    unit: "Y",
    quantity: 9,
    code: "9Y",
  },
  {
    id: 16,
    label: "10 años",
    unit: "Y",
    quantity: 10,
    code: "10Y",
  },
  {
    id: 17,
    label: "11 años",
    unit: "Y",
    quantity: 11,
  },
  {
    id: 18,
    label: "12 años",
    unit: "Y",
    quantity: 12,
  },
  {
    id: 19,
    label: "13 años",
    unit: "Y",
    quantity: 13,
  },
  {
    id: 20,
    label: "14 años",
    unit: "Y",
    quantity: 14,
  },
  {
    id: 21,
    label: "15 años",
    unit: "Y",
    quantity: 15,
  },
  {
    id: 22,
    label: "16 años",
    unit: "Y",
    quantity: 16,
  },
  {
    id: 23,
    label: "17 años",
    unit: "Y",
    quantity: 17,
  },
  {
    id: 24,
    label: "18 años",
    unit: "Y",
    quantity: 18,
  },
  {
    id: 25,
    label: "19 años",
    unit: "Y",
    quantity: 19,
  },
  {
    id: 26,
    label: "20 años",
    unit: "Y",
    quantity: 20,
  },
];