import ageData from "../utils/age-data";
import ageDataSenior from "../utils/age-data-senior";

export default class AgeService {
  static async getAges() {
    try {
      let ages = ageData;
      return ages;
    } catch (error) {
      return {
        status: "error",
        message: "Ocurrio un error al obtener las edades",
      };
    }
  }

  static async getAgesSenior() {
    try {
      let ages = ageDataSenior;
      return ages;
    } catch (error) {
      return {
        status: "error",
        message: "Ocurrio un error al obtener las edades",
      };
    }
  }
}

